import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TreeNode } from '../models/tree-node.model';


export class TreeNodeCheckboxComponent {
   node: TreeNode;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'tree-node-checkbox',
  encapsulation: ViewEncapsulation.None,
  styles: [],
  template: `
    <ng-container *mobxAutorun="{dontDetach: true}">
      <input
        class="tree-node-checkbox"
        type="checkbox"
        (click)="node.mouseAction('checkboxClick', $event)"
        [checked]="node.isSelected"
        [indeterminate]="node.isPartiallySelected"/>
    </ng-container>
  `
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'node': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
