import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { TreeNode } from '../models/tree-node.model';


export class LoadingComponent {
   template: TemplateRef<any>;
   node: TreeNode;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  encapsulation: ViewEncapsulation.None,
  selector: 'tree-loading-component',
  template: `
    <span *ngIf="!template">loading...</span>
    <ng-container
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: node }">
    </ng-container>
  `,
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: Input },],
'node': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
