import { Directive, Input, HostListener, Renderer, ElementRef, DoCheck, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { TreeDraggedElement } from '../models/tree-dragged-element.model';

const DRAG_OVER_CLASS = 'is-dragging-over';


export class TreeDragDirective implements AfterViewInit, DoCheck, OnDestroy {
   draggedElement;
   treeDragEnabled;
  private readonly dragEventHandler: (ev: DragEvent) => void;

  constructor(private el: ElementRef, private renderer: Renderer, private treeDraggedElement: TreeDraggedElement, private ngZone: NgZone) {
      this.dragEventHandler = this.onDrag.bind(this);
  }

  ngAfterViewInit() {
    let el: HTMLElement = this.el.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      el.addEventListener('drag', this.dragEventHandler);
    });
  }

  ngDoCheck() {
    this.renderer.setElementAttribute(this.el.nativeElement, 'draggable', this.treeDragEnabled ? 'true' : 'false');
  }

  ngOnDestroy () {
    let el: HTMLElement = this.el.nativeElement;
    el.removeEventListener('drag', this.dragEventHandler);
  }

   onDragStart(ev) {
    // setting the data is required by firefox
    ev.dataTransfer.setData('text', ev.target.id);
    this.treeDraggedElement.set(this.draggedElement);
    if (this.draggedElement.mouseAction) {
        this.draggedElement.mouseAction('dragStart', ev);
    }
  }

  onDrag(ev) {
    if (this.draggedElement.mouseAction) {
        this.draggedElement.mouseAction('drag', ev);
    }
  }

   onDragEnd() {
    if (this.draggedElement.mouseAction) {
      this.draggedElement.mouseAction('dragEnd');
    }
    this.treeDraggedElement.set(null);
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[treeDrag]'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer, },
{type: TreeDraggedElement, },
{type: NgZone, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'draggedElement': [{ type: Input, args: ['treeDrag', ] },],
'treeDragEnabled': [{ type: Input },],
'onDragStart': [{ type: HostListener, args: ['dragstart', ['$event'], ] },],
'onDragEnd': [{ type: HostListener, args: ['dragend', ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
